export const historyContainer:string =  "w-full flex flex-col justify-center md:px-28 text-2xl z-0 select-none ";
export const monthContainer:string =  "mb-12 border border-grey-100 ";
export const monthGrid:string =  "w-full h-full grid grid-cols-7 divide-x divide-y ";
export const monthHeader:string =  "col-span-1 border-b-2 border-red-500 flex justify-evenly items-center xl:text-4xl py-4 lg:pt-8 lg:pb-3 ";
export const weekHeaderContainer:string =  "w-full grid grid-cols-7 divide-x border-y border-grey-100 text-sm md:text-lg ";
export const weekContainer:string =  "md:px-3 py-2 text-center truncate ";
export const dayContainer:string =  "md:h-24 h-16 text-sm md:text-2xl px-5 py-2 cursor-pointer hover:shadow-xl md:flex ";
export const calenderIcons:string =  'mt-1 md:ml-4 lg:ml-7 md:text-[23px] text-red-500 ';
export const fixedOptionBox:string =  'fixed top-[5em] left-[1em] md:left-[2em] z-40 ';
export const scrollTracker:string =  "fixed right-0 top-[7%] left-0 h-[10px] origin-[0%] z-20 bg-red-500 ";
export const dayLink:string =  "leading-relaxed truncate cursor-pointer hover:text-blue-500 ";
export const instruction = "sticky text-xs md:text-lg top-[1em] h-[2em] z-10 opacity-75 bg-blue-200 px-[1em] self-center ";